export const shirtSizes = ["S", "M", "L", "XL", "2XL"]

export const shirtSizesSeparatedByPipes = "S|M|L|XL|2XL"

export const findColor = name =>
  name
    .substr(name.indexOf("-"))
    .split("/")[0]
    .replace("-", "")
    .trim()

export const colorOptionsWithPipes = colors => {
  if (!colors) {
    return []
  }
  return colors.join("|")
}
